/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          var topMenuFooter = $(".nav_footer li"),
              menuFooterItems = topMenuFooter.find("a");

          menuFooterItems.on('click', function() {
              const heightHeader = $(".banner").outerHeight();

              var href = $(this).attr("href").split("#"),
                  hrefSplit = href[1],

                  offsetTop = $("#" + hrefSplit).offset().top - heightHeader;
              console.log(offsetTop);
              $("html, body").stop().animate({
                  scrollTop: offsetTop
              }, 600);
          });

//------------------------------------------------------------------------
//Animation  --> Header
//------------------------------------------------------------------------

          $(window).scroll(function(){
              if ($(window).scrollTop() >= 300) {
                  $('header').addClass('fixed-header');

              }
              else {
                  $('header').removeClass('fixed-header');

              }
          });


          jQuery(document).ready(function($){
              var url = "https://"+window.location.hostname+"/";
              //var url = window.location.hostname;
              //url ="http://"+url+":3000/balthazar/";
              var popupOpen = localStorage.getItem('popup');
              if(popupOpen && window.location.href === url) {
                  var l = document.getElementsByClassName('download-bottom');
                      l[0].click();
                  localStorage.removeItem("popup");
              }
              var isLateralNavAnimating = false;
              //open/close lateral navigation
              $('.cd-nav-trigger').on('click', function(event){

                  event.preventDefault();
                  //stop if nav animation is running
                  if( !isLateralNavAnimating ) {
                      if($(this).parents('.csstransitions').length > 0 ) {isLateralNavAnimating = true; }
                      $('body').toggleClass('navigation-is-open');
                      $('.cd-navigation-wrapper').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                          //animation is over
                          isLateralNavAnimating = false;
                      });
                  }
                  $('header').removeClass('fixed-header');
              });
          });

          $("#cd-nav").click(function(e) {
              if(e.target.id !== '.cd-nav-trigger'){
                  $("body").removeClass('navigation-is-open');
              }
          });
          $("#cd-nav").click(function(e) {
              if(e.target.id === '.cd-nav-trigger'){
                  $('.navigation-is-open .cd-nav .cd-navigation-wrapper').hide();
              }
          });

//------------------------------------------------------------------------
//Animation  --> Tabs
//------------------------------------------------------------------------
          $.fn.tabbing = function (options) {
              var opts = {delayTime : 300};
              options = options || {};
              opts = $.extend(opts,options);
              return this.each(function () {
                  $(this).on('click', function (event) {
                      event.preventDefault();
                      var sum = 0;
                      $(this).prevAll().each(function(){  sum += $(this).width();});
                      var get = document.getElementById('tabs').scrollWidth;
                      var dist = sum - ( $(this).parent().width() - $(this).width()) / 2;
                      if(dist < 0){
                          dist = 0;
                      }
                      /* else if(dist+sum > get){
                        dist = get-sum+dist+dist;
                      } */
                      $(this).parent().animate({
                          scrollLeft: dist
                      },opts.delayTime);
                  });
              });
          };
          $('#tabs li').tabbing();


         /* $('#tabs li').click(function(){
              var  hashit = $(this).find('a').attr('href')
              var autoHeight = $(hashit).height() + 30;
              $('.tab-content').animate({height: autoHeight}, 100);
          });*/

//------------------------------------------------------------------------
//Animation  --> WOW
//------------------------------------------------------------------------

          new WOW().init();


//------------------------------------------------------------------------
//Animation  --> scroll to
//------------------------------------------------------------------------
              $("a[href*='#form']").mPageScroll2id();
          $("a[href*='']").mPageScroll2id();


//------------------------------------------------------------------------
//Animation  -->
//------------------------------------------------------------------------
         /* $(document).ready(function() {
              var s = $(".expertise");
              var pos = s.position();
              var s2 = $(".counter");
              var pos2 = s2.position();
              $(window).scroll(function() {
                  var windowpos = $(window).scrollTop();
                  if (windowpos >= pos.top) {
                      s.addClass("stick");
                  } else {
                      s.removeClass("stick");
                  }
                  if (windowpos > pos2.top) {
                      s.removeClass("stick");
                  }
              });
          });*/





//------------------------------------------------------------------------
//Animation  --> Paralax
//------------------------------------------------------------------------
          $(window).resize(function(){
              var parallaxHeight = Math.max($(window).height() * 0.6, 200) ;
              //$('.parallax-window').height(parallaxHeight);
          }).trigger('resize');


              if (window.matchMedia("(max-width: 1025px)").matches) {
                  $('.sep_right').removeClass('parallax-window');
              }

//------------------------------------------------------------------------
//Animation  --> Read more
//------------------------------------------------------------------------
          var lineHeight = 25;
          var numLines = 7;
          if (window.matchMedia("(max-width: 992px)").matches) {
              numLines = 11;
          }
          $('.readmore').readmore({
              speed: 1000,
              collapsedHeight: lineHeight * numLines,
              heightMargin: lineHeight * 1,
              moreLink: '<a href="#">Voir plus </a>',
              lessLink: '<a href="#">Voir moins</a>',
          });
//------------------------------------------------------------------------
//Animation  --> aos
//------------------------------------------------------------------------
          jQuery(document).ready(function($){
          AOS.init({
              // Global settings:
              disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
              startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
              initClassName: 'aos-init', // class applied after initialization
              animatedClassName: 'aos-animate', // class applied on animation
              useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              disableMutationObserver: false, // disables automatic mutations' detections (advanced)
              debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
              throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
          });
          AOS.refreshHard();
          });
//------------------------------------------------------------------------
//Animation  --> parallax image entity
//------------------------------------------------------------------------


        /*  jQuery(window).scroll(function() {
              if (jQuery(this).scrollTop()){
                  jQuery('.parallax_header').show( 1000, function() {
                      $( this ).fadeIn(500);
                  });
              }
          });*/
   /*       jQuery('.download-bottom').on('click', function () {
              window.history.pushState("", "Lab", "labthazar/");
          });
          jQuery('.popmake-close').on('click', function () {
              window.history.go(-1);
          });*/
//------------------------------------------------------------------------
//Animation  --> Equipe + titles tab
//------------------------------------------------------------------------

          window.addEventListener('load', function () {
              PannableMenu.init({
                centered: function (el) { return !el.classList.contains('team-pannable-menu'); },
                baseOffset: function (el) { return el.classList.contains('team-pannable-menu') ? 50 : 0; },
                resetPositionOnMouseLeave: false,
                speed: 0.15
              });
          });

          window.addEventListener('resize', function () {
              PannableMenu.update();
          });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        window.addEventListener('load', function () {

          if(!document.body.classList.contains('home')){return;}

          var DEFAULT_COLOR = '#898a9f';

          var homeLeftImageAlpha = document.getElementById('Home-left-image-alpha'),
            homeRightImageAlpha = document.getElementById('Home-right-image-alpha'),
            homeTopImageAlpha = document.getElementById('Home-top-image-alpha');

          var images = {
            left: new ImageWithMask({
              fromUrl: homeLeftImageAlpha.value,
              grayscaleAlpha: true,
              color: DEFAULT_COLOR,
              onLoad: function () {
                homeLeftImageAlpha.parentNode.insertBefore(this.element(), homeLeftImageAlpha);
              }
            }),
            right: new ImageWithMask({
              fromUrl: homeRightImageAlpha.value,
              grayscaleAlpha: true,
              color: DEFAULT_COLOR,
              onLoad: function () {
                homeRightImageAlpha.parentNode.insertBefore(this.element(), homeRightImageAlpha);
              }
            }),
            top: new ImageWithMask({
              fromUrl: homeTopImageAlpha.value,
              grayscaleAlpha: true,
              color: DEFAULT_COLOR,
              onLoad: function () {
                homeTopImageAlpha.parentNode.insertBefore(this.element(), homeTopImageAlpha);
              }
            })
          };

          $('a[data-related-image]')
            .on('mouseenter', function (ev) {
              var imageId = this.getAttribute('data-related-image'),
                iwm = imageId in images ? images[imageId] : null;

              if(iwm && iwm.isReady()) {
                iwm.fadeTo(this.getAttribute('data-color'));
              }

              document.querySelector('.' + imageId + '-img').classList.add('is-focused');
              document.body.classList.add('hovering-' + imageId + '-img');
            })
            .on('mouseleave', function (ev) {
              var imageId = this.getAttribute('data-related-image'),
                iwm = imageId in images ? images[imageId] : null;

              if(iwm && iwm.isReady()) {
                iwm.fadeTo(DEFAULT_COLOR);
              }

              document.querySelector('.' + imageId + '-img').classList.remove('is-focused');
              document.body.classList.remove('hovering-' + imageId + '-img');
            });



          MouseNudge.init([
            '.left-img',
            '.left-img img',
            '.left-img img',
            '.right-img',
            '.top-img',
            '.title_home'
          ].join(', '));

        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
      'partenaires': {
          init: function() {

              function setupHeight() {
                  $( '.partners__box' ).each(function() {
                      var wdth = $(this).width();
                      $(this).height(wdth);
                  });
              }


			  $(".partners__box").click(function(e) {
				var modal = $(this).attr("data-target");
				var viewportOffset = $(this)[0].getBoundingClientRect();
				// these are relative to the viewport
				var top = viewportOffset.top;
				var left = viewportOffset.left;
				$(modal).css("transition", "all 0s ease");
				$(modal).css("display", "block");
				$(modal).css("width", $(this)[0].offsetWidth + "px");
				$(modal).css("height", $(this)[0].offsetHeight + "px");
				$(modal).css("top", top + "px");
				$(modal).css("left", left + "px");

                $(modal).parents("body").addClass("modal-open");
                $("body.modal-open").append('<div class="modal-backdrop"></div>');

              setTimeout(function() {
                $(modal).addClass("open");
				$(modal).css(
                  "transition",
                  "all .4s ease-in-out 0s"
                );
              }, 10);
              setTimeout(function() {
                $(modal)
                  .find(".logos-modal-dialog")
                  .addClass("open");
				}, 400);
			  });
              $(".modalPartners .close").click(function(e) {
				var openModal = $(".modalPartners.open");
                openModal.css("display", "block");
                openModal.removeClass("open");
                $(openModal)
                .find(".logos-modal-dialog")
                .removeClass("open");
                setTimeout(function() {
                  openModal.css("display", "none");
                }, 510);
                $("body.modal-open").find(".modal-backdrop").remove();
                $(openModal).parents("body").removeClass("modal-open");
              });


			  $(".partners__box").each(function() {
                var imageUrl = $(this)
                  .find("img")
                  .attr("src");
                var modal = $(this).attr("data-target");
                  $(this)
                  .find(".overlay")
                  .css("background-image", "url(" + imageUrl + ")");
			  });


              jQuery(document).ready(function($){

                  setupHeight($('.partners__box-1'));

                  $('.partners__box').each(function () {
                      var imageUrl = $(this).find('img').attr('src');
                      $(this).find('.overlay').css('background-image', 'url(' + imageUrl + ')');
                  });

                  $('body.page-template-template-partenaires').parent().css( "overflow-x", "inherit" );

              });

              jQuery(window).resize(function($){ setupHeight(); });

          }
      }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
