var MouseNudge = (function () {

	var instances = null;
	
	var hasTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints;

	var vw = viewportWidth(),
		vh = viewportHeight(),
		max = 0,
		may = 0,
		mrx = 0,
		mry = 0;



	function viewportWidth () { return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth; }
	function viewportHeight () { return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; }



	function MNObject (el) {
		this._el = el;
		this._isMoving = false;
		this._targetX = 0;
		this._targetY = 0;
		this._currentX = 0;
		this._currentY = 0;
		this._tick = tick.bind(this);

		this.amplitude = this._el.hasAttribute('data-mouse-nudge-amplitude')
			? parseFloat(this._el.getAttribute('data-mouse-nudge-amplitude'))
			: 60;
		this.speed = this._el.hasAttribute('data-mouse-nudge-speed')
			? parseFloat(this._el.getAttribute('data-mouse-nudge-speed')) / 100
			: 0.15;
	}

	MNObject.prototype.element = function () {
		return this._el;
	};

	MNObject.prototype.changeTarget = function (x, y) {
		this._targetX = -x * this.amplitude;
		this._targetY = -y * this.amplitude;

		if(!this._isMoving) {
			this._isMoving = true;
			this._rafId = window.requestAnimationFrame(this._tick);
		}
	};

	MNObject.prototype.on = function (type, handler) {
		this['_on_' + type] = handler;
	};



	function tick () {
		var dx = this._targetX - this._currentX,
			dy = this._targetY - this._currentY;

		this._currentX += dx * this.speed;
		this._currentY += dy * this.speed;

		this._el.style.transform = 'translate(' + this._currentX + 'px, ' + this._currentY + 'px)';

		this._on_tick && this._on_tick(this._el, this._currentX, this._currentY);

		if(dx < 1 && dy < 1) {
			this._isMoving = false;
		}
		else {
			this._rafId = window.requestAnimationFrame(this._tick);
		}
	}



	var __idle__ = true;

	function init (selector) {
		var added = Array.prototype.slice.call(document.querySelectorAll(selector)).map(function (el) {
			return new MNObject(el);
		});

		if(added.length < 1) {
			return;
		}

		instances = instances
			? instances.concat(added)
			: added;

		if(__idle__) {
			__idle__ = null;
			
			if(window.DeviceOrientationEvent && hasTouchScreen) {
				MouseNudge._betaCompensation = -15;
				window.addEventListener('deviceorientation', function (ev) {
					max = -ev.gamma;
					may = -(ev.beta + MouseNudge._betaCompensation);
					mrx = max / 30;
					mry = may / 30;
					
					for(var i = 0 ; i < instances.length ; i++) {
						instances[i].changeTarget(mrx, mry);
					}
				});
			}
			else {
				window.addEventListener('mousemove', function (ev) {
					max = ev.pageX - (vw / 2);
					may = ev.pageY - (vh / 2);
					mrx = max / (vw / 2);
					mry = may / (vh / 2);

					for(var i = 0 ; i < instances.length ; i++) {
						instances[i].changeTarget(mrx, mry);
					}
				});
			}

			window.addEventListener('resize', function (ev) {
				vw = viewportWidth();
				vh = viewportHeight();
			});
		}

		return added.length > 1
			? added
			: added[0];
	}



	return {
		init: init
	};

})();