var PannableMenu = function () {
    var MENU_SELECTOR = '.ui-pannable-menu',
        PANNER_SELECTOR = MENU_SELECTOR + '-panner',
        ITEM_SELECTOR = MENU_SELECTOR + '-item',
        ITEM_ON_SELECTOR = ITEM_SELECTOR + '_on';

    var CAN_TOUCH_THIS = 'ontouchstart' in document.body || navigator.msMaxTouchPoints || navigator.maxTouchPoints;

    var instances = null;

    function PMObject (el, options) {
        this.init(el, options);
    }

    function initForMouse (el, options) {
        options = options || {};

        options.centered = options.centered !== undefined
            ? (typeof options.centered == 'function' ? options.centered(el) : options.centered)
            : true;

        options.baseOffset = options.baseOffset !== undefined
            ? (typeof options.baseOffset == 'function' ? options.baseOffset(el) : options.baseOffset)
            : 0;

        options.resetPositionOnMouseLeave = options.resetPositionOnMouseLeave !== undefined
            ? (typeof options.resetPositionOnMouseLeave == 'function' ? options.resetPositionOnMouseLeave(el) : options.resetPositionOnMouseLeave)
            : true;

        options.speed = options.speed !== undefined
            ? (typeof options.speed == 'function' ? options.speed(el) : options.speed)
            : 0.2;

        this._el = el;
        this._x;
        this._width;
        this._panner = this._el.querySelector(PANNER_SELECTOR);
        this._pannerCurrentX = 0;
        this._pannerTargetX = 0;
        this._pannerTargetXReached = true;
        this._currentItem = this._el.querySelector(ITEM_ON_SELECTOR);
        this._currentItemX;
        this._currentItemWidth;
        this._baseX;
        this._overflow;
        this._mouseEntered;
        this._process = processForMouse.bind(this);
        this._centered = options.centered;
        this._baseOffset = options.baseOffset;

        this.speed = options.speed;
        this.resetPositionOnMouseLeave = options.resetPositionOnMouseLeave;

        this.update();

        this._el.addEventListener('mousemove', onMouseMove.bind(this));
        this._el.addEventListener('mouseenter', onMouseEnter.bind(this));
        this._el.addEventListener('mouseleave', onMouseLeave.bind(this));
    }

    function updateForMouse () {
        this._x = pageX(this.el);
        this._width = this._el.offsetWidth;
        this._currentItemX = this._currentItem.offsetLeft;
        this._currentItemWidth = this._currentItem.offsetWidth;

        var items = this._el.querySelectorAll(ITEM_SELECTOR);
        var pannerPaddingRight = parseFloat(window.getComputedStyle(this._panner).paddingRight),
            pannerWidth = items[items.length - 1].offsetLeft + items[items.length - 1].offsetWidth + pannerPaddingRight;

        var a = this._centered ? 0.5 : 0;

        this._baseX = (this._width * a) - this._currentItemX - (this._currentItemWidth * a) + this._baseOffset;
        this._overflow = (pannerWidth + this._baseOffset) - this._width;

        this._panner.style.width = pannerWidth + 'px';
        this._panner.style.left = this._baseX + 'px';
    }

    function processForMouse () {
        var dx = this._pannerTargetX - this._pannerCurrentX;

        if(Math.abs(dx) * this._overflow < 1) {
            this._pannerCurrentX = this._pannerTargetX;
            this._pannerTargetXReached = true;
        }
        else {
            this._pannerCurrentX += dx * this.speed;
        }

        this._panner.style.transform = 'translateX(' + pannerAbsoluteXinPixels.call(this, this._pannerCurrentX) + 'px)';

        if (this._mouseEntered) {
            this._rafId = window.requestAnimationFrame(this._process);
        }
    }

    function initForTouch (el, options) {
        this._el = el;
        this._panner = this._el.querySelector(PANNER_SELECTOR);
        this._currentItem = this._el.querySelector(ITEM_ON_SELECTOR);

        this._el.style.overflowX = 'auto';

        this.update();
    }

    function updateForTouch () {
        this._width = this._el.offsetWidth;
        this._el.currentItemX = this._currentItem.offsetLeft;
        this._currentItemWidth = this._currentItem.offsetWidth;

        var items = this._el.querySelectorAll(ITEM_SELECTOR);
        var pannerPaddingRight = parseFloat(window.getComputedStyle(this._panner).paddingRight),
            pannerWidth = items[items.length - 1].offsetLeft + items[items.length - 1].offsetWidth + pannerPaddingRight;

        var a = this._centered ? 0.5 : 0;

        this._baseX = this._currentItemX - (this._width * a) + (this._currentItemWidth * a) + this._baseOffset;

        this._panner.style.width = pannerWidth + 'px';
        this._el.scrollLeft = this._baseX;
    }

    PMObject.prototype.init = CAN_TOUCH_THIS ?
        initForTouch :
        initForMouse;

    PMObject.prototype.update = CAN_TOUCH_THIS ?
        updateForTouch :
        updateForMouse;

    function onMouseEnter (ev) {
        window.cancelAnimationFrame(this._rafId);

        var a = this._centered ? 0.5 : 0,
            b = this._centered ? 1 / a : 1;

        this._mouseEntered = true;
        this._pannerTargetX = ((ev.pageX - this._x) / (this._width * 0.95) - a) * b;
        this._pannerTargetX = Math.max(Math.min(this._pannerTargetX, 1), -1);
        this._rafId = window.requestAnimationFrame(this._process);
    }

    function onMouseMove (ev) {
        if (this._mouseEntered) {
            var a = this._centered ? 0.5 : 0,
                b = this._centered ? 1 / a : 1;

            this._pannerTargetX = ((ev.pageX - this._x) / (this._width * 0.95) - a) * b;
            this._pannerTargetX = Math.max(Math.min(this._pannerTargetX, 1), -1);
            this._pannerTargetXReached = false;
        }
    }

    function onMouseLeave (ev) {
        this._mouseEntered = false;
        window.cancelAnimationFrame(this._rafId);

        if(this.resetPositionOnMouseLeave) {
            this._pannerTargetX = 0;
            this._pannerCurrentX = 0;
            this._panner.style.transform = 'translateX(0)';
        }
        else {
            if(this._pannerTargetXReached) {
                this._pannerCurrentX = this._pannerTargetX;
            }
            else {
                this._pannerTargetX = this._pannerCurrentX;
            }

            this._panner.style.transform = 'translateX(' + pannerAbsoluteXinPixels.call(this, this._pannerCurrentX) + 'px)';
        }
    }



    function windowWidth () {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    function pageX (el) {
        var ox = 0;

        while (el) {
            ox += el.offsetLeft;
            el = el.offsetParent;
        }

        return ox;
    }

    function translateFromMatrix (str) {
        str = str.match(/[+-]?\d*[.]?\d+(?=,|\))/g);

        return {
            x: str[4],
            y: str[5]
        };
    }

    function pannerAbsoluteXinPixels (rx) { // Attend this == une instance de PMObject (utiliser via .call)
        return rx > 0 ?
            Math.min(-rx * (this._overflow + this._baseX), 0) :
            Math.max(rx * this._baseX, 0);
    }



    function init (options) {
        if (instances === null) {
            instances = Array.prototype.slice.call(document.querySelectorAll(MENU_SELECTOR)).map(function (el) {
                return new PMObject(el, options);
            });
        }
    }

    function update () {
        if (instances && instances.length) {
            for (var i = 0; i < instances.length; i++) {
                instances[i].update();
            }
        }
    }

    function getInstances () {
        return Array.prototype.slice.call(instances);
    }



    return {
        init: init,
        update: update,
        getInstances: getInstances
    };

}();