var ImageWithMask = (function () {

	function getAlphaFromGrayscale (img) {
		var can = document.createElement('canvas');
		can.width = img.width;
		can.height = img.height;
		
		var ctx = can.getContext('2d');
		ctx.drawImage(img, 0, 0);
		
		var data = ctx.getImageData(0, 0, img.width, img.height);
		
		var i = 0, rgb;
		while(i < data.data.length) {
			rgb = data.data[i++] + data.data[i++] + data.data[i++];
			data.data[i++] = rgb / 3;
		}
		
		ctx.putImageData(data, 0, 0);
		
		return can;
	}



	function IWMObject (options) {
		this._isReady = false;
		this._canvas = document.createElement('canvas');
		this._context = this._canvas.getContext('2d');
		this._color = options.color || '#000';
		
		options.grayscaleAlpha = options.grayscaleAlpha !== undefined ? options.grayscaleAlpha : true;
		
		if(options.fromEl) {
			this.resetWith(null, options.grayscaleAlpha ? getAlphaFromGrayscale(options.fromEl.cloneNode()) : options.fromEl.cloneNode());
			options.fromEl.parentNode.insertBefore(this._canvas, options.fromEl);
			this._isReady = true;
			options.onLoad && options.onLoad.call(this);
		}
		else if(options.fromUrl) {
			var that = this;
			var mask = new Image();
			mask.crossOrigin = "Anonymous";
			mask.onload = function () {
				this.onload = null;
				that.resetWith(null, options.grayscaleAlpha ? getAlphaFromGrayscale(this) : this);
				that._isReady = true;
				options.onLoad && options.onLoad.call(that);
			};
			mask.src = options.fromUrl;
		}
	}

	IWMObject.prototype.element = function () {
		return this._canvas;
	};

	IWMObject.prototype.isReady = function () {
		return this._isReady;
	};

	IWMObject.prototype.resetWith = function (color, mask) {
		if(mask) {
			this._mask = mask;
		}
		
		if(!this._mask) {
			return;
		}
		
		if(color) {
			this._color = color;
		}
		
		var w = this._mask.width,
			 h = this._mask.height;
		
		this._canvas.width = w;
		this._canvas.height = h;
		this._context.clearRect(0, 0, w, h);
		this._context.globalCompositeOperation = 'source-over';
		this._context.globalAlpha = 1;
		this._context.drawImage(this._mask, 0, 0, w, h);
		this._context.globalCompositeOperation = 'source-in';
		this._context.fillStyle = this._color;
		this._context.fillRect(0, 0, w, h);
		this._context.globalCompositeOperation = 'source-over';
	};

	IWMObject.prototype.fadeTo = function (color) {
		if(this._isFading) {
			window.cancelAnimationFrame(this._rafId);
		}
		
		this._isFading = true;
		
		var w = this._canvas.width,
			 h = this._canvas.height;
		
		this.resetWith();
		
		this._context.globalCompositeOperation = 'source-atop';
		this._context.globalAlpha = 0.1;
		this._context.fillStyle = color;
		
		var c = 0;
		var fill = (function () {
			this._context.fillRect(0,0, w, h);
			
			if(c == 20) {
				this._context.globalAlpha = 0.2;
			}
			
			if(c == 40) {
				this._context.globalAlpha = 0.3;
			}
			
			if(c < 60) {
				this._rafId = window.requestAnimationFrame(fill);
			}
			else {
				fill = null;
				this._color = color;
				this._context.globalAlpha = 1;
				this._context.fillRect(0,0, w, h);
				this._context.globalCompositeOperation = 'source-over';
				this._isFading = false;
			}
			
			c++;
		}).bind(this);
		
		this._rafId = window.requestAnimationFrame(fill);
	};



	return IWMObject;

})();